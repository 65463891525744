import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import App from "./containers/App"
import configureStore from "./configureStore";
import { DataProvider } from "./context/DataContext";
import { APIProvider } from "./context/ApiContext";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallbackComponent from "./components/ErrorBoundary/ErrorBoundaryComponent";
import { BrowserRouter } from 'react-router-dom';
import { analyticsInitialize } from './utils/analytics';

// Load the favicon and the .htaccess file
// import "!file-loader?name=[name].[ext]!./images/chase-favicon.ico";

const initialState = {};
const store = configureStore(initialState);
const root = ReactDOM.createRoot(
  document.getElementById('root')
);

analyticsInitialize();

root.render(
  <Provider store={store}>
      <BrowserRouter>
        <DataProvider>
          <APIProvider>
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <App />
            </ErrorBoundary>
          </APIProvider>
        </DataProvider>
      </BrowserRouter>
  </Provider>
);
