import React, { memo, useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { ApiDispatchContext } from "../../context/ApiContext";
import { useInjectReducer } from "../../utils/injectReducer";
import makeSelectReinvestigateCasesDetails from "./selectors";
import { getUrlParameter } from "../../constants/commonFunction";
import { push } from 'connected-react-router';
import CommonNavbar from "../../components/CommonNavbar/index";
import ErrorAlert from "../../components/ErrorAlert";
import path from "../../constants/pathConstants";
import CaseCard from "./CaseCard";
import reducer from "./reducer";
import { investigatorCardData, vendorCardData } from "../../constants/reinvestigateCasesTabConstants";
import CardTabs from "../../components/CardTabs";
import { getEmployeeRole } from "../App/selectors";
import { caseReInvestigateDetails } from "./actions";
export function ReinvestigateCasesDetails({ allCases,employeeRole,updateCaseDetails }) {
  useInjectReducer({ key: "reinvestigateCasesDetails", reducer });
  const [selectedValue, setSelectedValue] = useState();
  const [tabList, setTabList] = useState(employeeRole === "vendor" ? vendorCardData : investigatorCardData);
  const [lobType, setLobType] = useState("");
  const api = useContext(ApiDispatchContext);
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (allCases) {
      selectedCard(allCases);
    } else {
      let param = {
        "caseType": "reinvestigated",
      }
      api.getCasesDetails(param).then(data => {
        if (data.data.success == true) {
          selectedCard(data.data.data.rows)
        } else {
          setModalTitle("Error");
          setModalBody('Please try after sometime');
          toggle();
        }
      }).catch(err => {
        setModalTitle("Error");
        setModalBody('Please try after sometime');
        toggle();
      })
    }

  }, []);

  const selectedCard = (cards) => {
    const selectedCase =  cards.find(item => item.some(field => field.label === "Case No." && field.value === Number(getUrlParameter('case'))));
    setSelectedValue([selectedCase]);
    const lobType = selectedCase.find(item => item.label === "LOB")?.value || "";
    if(lobType === "A&H") {
      const allTabs = [...tabList];
      allTabs.splice(4, 3);
      setTabList(allTabs);
    }
    updateCaseDetails({lob:selectedCase.find(item => item.label === "LOB")?.value ||""})
  }

  return (
    <div className="reinvestigate-cases-details-page">
      <CommonNavbar title="Reinvestigate Cases" backButton={path.REINVESTIGATE_CASES} search={false} download={false} />
      <div style={{ marginTop: "20px" }}></div>
      <CaseCard selectCards={selectedValue} />
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
       {employeeRole === "vendor" ? (
      <CardTabs tabData={tabList} />
      ) : <CardTabs tabData={tabList} />}
    </div>
  );
}

ReinvestigateCasesDetails.propTypes = {
  dispatch: PropTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({
  reinvestigateCasesDetails: makeSelectReinvestigateCasesDetails(),
  employeeRole: getEmployeeRole(),
});

export function mapDispatchToProps(dispatch) {
  return {
    redirectPath: (path) => dispatch(push(path)),
    updateCaseDetails:(data)=> dispatch(caseReInvestigateDetails(data))
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose( withConnect, memo)(ReinvestigateCasesDetails);